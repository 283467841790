import { useLocation, useNavigate } from 'react-router'
import { useEffect } from 'react'

export const ModalRoute = ({ component: Component, shouldRenderAsModal }) => {
  const location = useLocation()
  const navigate = useNavigate()

  // Check if we navigated here programmatically with state
  const isModal = location.state?.isModal
  const background = location.state?.background

  useEffect(() => {
    // Handle escape key to close modal only in modal mode
    const handleEscape = (e) => {
      if (e.key === 'Escape' && shouldRenderAsModal && isModal) {
        // Navigate back to the background location if available, preserving search params
        navigate({
          pathname: background?.pathname || '/',
          search: background?.search || '',
        }, {
          state: background?.state
        })
      }
    }
    window.addEventListener('keydown', handleEscape)
    return () => window.removeEventListener('keydown', handleEscape)
  }, [navigate, isModal, background, shouldRenderAsModal])

  // If this is a modal route and we have modal state, render as modal
  if (shouldRenderAsModal && isModal) {
    return (
      <div 
        className="modal-container"
        onClick={(e) => {
          // Close on backdrop click
          if (e.target === e.currentTarget) {
            // Navigate back to the background location if available, preserving search params
            navigate({
              pathname: background?.pathname || '/',
              search: background?.search || '',
            }, {
              state: background?.state
            })
          }
        }}
      >
        <div className="modal-content">
          <Component />
        </div>
      </div>
    )
  }

  // Otherwise render as full page
  return <Component />
}
